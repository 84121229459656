import React from "react";
import NavbarItem from "../NavbarItem/NavbarItem";
import styles from "./GameInterfaceOverview.module.scss";
import { NavbarImages } from "../../../utils/enums";

const GameInterfaceOverview = () => (
  <div className={styles.navBarSection}>
    <NavbarItem icon={NavbarImages.HOME} align="left">
      Main Page: This is where you can view your collection of heroes. Each hero
      is unique and can be developed through various quests.
    </NavbarItem>
    <NavbarItem icon={NavbarImages.QUESTS} align="right">
      Quests: Send your heroes on missions to gain experience, treasures, and
      other rewards. These quests are crucial for character growth and
      development.
    </NavbarItem>
    <NavbarItem icon={NavbarImages.SHOP} align="left">
      Shop: Use Telegram stars to purchase in-game products.
    </NavbarItem>
    <NavbarItem icon={NavbarImages.RATING} align="right">
      Leaderboard: Check out the top 100 most powerful players. Compete with
      others and strive to see your name climb the ranks.
    </NavbarItem>
  </div>
);

export default GameInterfaceOverview;
