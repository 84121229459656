// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__VPntA {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  height: 100%;
  overflow: auto;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  position: relative;
  padding: 0 8px;
}

.styles_wrapper__VPntA::-webkit-scrollbar {
  display: none;
}

.styles_text__O6BYs {
  background: #f4e4bc;
  border: 2px solid #b59e60;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 15px;
}`, "",{"version":3,"sources":["webpack://./src/pages/About/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,iBAAA;EACA,cAAA;EACA,YAAA;EACA,cAAA;EACA,qBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,mBAAA;EACA,kBAAA;EACA,cAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,mBAAA;EACA,yBAAA;EACA,uCAAA;EACA,aAAA;EACA,mBAAA;AACF","sourcesContent":[".wrapper {\n  width: 100%;\n  max-width: 1100px;\n  margin: 0 auto;\n  height: 100%;\n  overflow: auto;\n  scrollbar-width: none;\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  align-items: center;\n  position: relative;\n  padding: 0 8px;\n}\n\n.wrapper::-webkit-scrollbar {\n  display: none;\n}\n\n.text {\n  background: #f4e4bc;\n  border: 2px solid #b59e60;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);\n  padding: 20px;\n  border-radius: 15px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__VPntA`,
	"text": `styles_text__O6BYs`
};
export default ___CSS_LOADER_EXPORT___;
