// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__y98Kn {
  padding: 16px;
  overflow: auto;
  height: 100%;
  max-width: 800px;
  margin: auto;
  scrollbar-width: none;
}

.styles_container__y98Kn::-webkit-scrollbar {
  display: none;
}

.styles_itemContainer__RHG9A {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

p {
  font-size: 16px;
  margin-bottom: 10px;
}

.styles_image__H8o\\+5 {
  max-width: 200px;
  height: auto;
  margin-bottom: 20px;
  max-height: 200px;
}

.styles_table__gfweN {
  width: 100%;
  border-collapse: collapse;
  background: linear-gradient(to bottom, #f4e4bc 0%, #f1d9a5 100%);
}

.styles_table__gfweN th,
.styles_table__gfweN td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.styles_table__gfweN th {
  background-color: #f2f2f2;
  font-weight: bold;
}

@media (max-width: 600px) {
  .styles_table__gfweN th,
  .styles_table__gfweN td {
    padding: 8px;
    font-size: 14px;
  }
  h2 {
    font-size: 20px;
  }
  p {
    font-size: 14px;
  }
}
.styles_text__1tkmd {
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  padding: 8px;
  border-radius: 8px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/Wiki/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,cAAA;EACA,YAAA;EACA,gBAAA;EACA,YAAA;EACA,qBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;;AAEA;EACE,eAAA;EACA,mBAAA;AACF;;AAEA;EACE,eAAA;EACA,mBAAA;AACF;;AAEA;EACE,gBAAA;EACA,YAAA;EACA,mBAAA;EACA,iBAAA;AACF;;AAEA;EACE,WAAA;EACA,yBAAA;EACA,gEAAA;AACF;;AAEA;;EAEE,sBAAA;EACA,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,yBAAA;EACA,iBAAA;AACF;;AAEA;EACE;;IAEE,YAAA;IACA,eAAA;EACF;EAEA;IACE,eAAA;EAAF;EAGA;IACE,eAAA;EADF;AACF;AAKA;EACE,YAAA;EACA,oCAAA;EACA,wCAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;AAHF","sourcesContent":[".container {\n  padding: 16px;\n  overflow: auto;\n  height: 100%;\n  max-width: 800px;\n  margin: auto;\n  scrollbar-width: none;\n}\n\n.container::-webkit-scrollbar {\n  display: none;\n}\n\n.itemContainer {\n  margin-bottom: 40px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\nh2 {\n  font-size: 24px;\n  margin-bottom: 10px;\n}\n\np {\n  font-size: 16px;\n  margin-bottom: 10px;\n}\n\n.image {\n  max-width: 200px;\n  height: auto;\n  margin-bottom: 20px;\n  max-height: 200px;\n}\n\n.table {\n  width: 100%;\n  border-collapse: collapse;\n  background: linear-gradient(to bottom, #f4e4bc 0%, #f1d9a5 100%);\n}\n\n.table th,\n.table td {\n  border: 1px solid #ddd;\n  padding: 8px;\n  text-align: center;\n}\n\n.table th {\n  background-color: #f2f2f2;\n  font-weight: bold;\n}\n\n@media (max-width: 600px) {\n  .table th,\n  .table td {\n    padding: 8px;\n    font-size: 14px;\n  }\n\n  h2 {\n    font-size: 20px;\n  }\n\n  p {\n    font-size: 14px;\n  }\n}\n\n\n.text {\n  color: white;\n  background-color: rgba(0, 0, 0, 0.5);\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);\n  padding: 8px;\n  border-radius: 8px;\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__y98Kn`,
	"itemContainer": `styles_itemContainer__RHG9A`,
	"image": `styles_image__H8o+5`,
	"table": `styles_table__gfweN`,
	"text": `styles_text__1tkmd`
};
export default ___CSS_LOADER_EXPORT___;
