export const aboutGameText =
  "Immerse yourself in the magical universe of The Path, an innovative MMO game. Experience the first play-to-earn RPG with NFT characters on Telegram, where players embark on various challenges, adventures, and trials. Every character is unique, providing limitless opportunities for customization and growth. As you advance, you can sell or trade your characters as NFTs in the in-game marketplace. With its rich narrative and engaging gameplay, The Path offers an enthralling adventure for everyone. Join today and embark on your journey in this mystical world, forming alliances and overcoming challenges with friends and fellow adventurers.";

export const CardImages = {
  COMMON: {
    MAIN: "cards/back/common.webp",
    PREVIEW: "cards/back/common_preview.webp",
  },
  DIVINE: {
    MAIN: "cards/back/divine.webp",
    PREVIEW: "cards/back/divine_preview.webp",
  },
  ELITE: {
    MAIN: "cards/back/elite.webp",
    PREVIEW: "cards/back/elite_preview.webp",
  },
  LEGENDARY: {
    MAIN: "cards/back/legendary.webp",
    PREVIEW: "cards/back/legendary_preview.webp",
  },
  RARE: {
    MAIN: "cards/back/rare.webp",
    PREVIEW: "cards/back/rare_preview.webp",
  },
};

export const NavbarImages = {
  HOME: {
    MAIN: "navbar/home.webp",
  },
  QUESTS: {
    MAIN: "navbar/quests.webp",
  },
  RATING: {
    MAIN: "navbar/rating.webp",
  },
  SHOP: {
    MAIN: "navbar/shop.webp",
  },
};

export const ClassImages = {
  ASSASSIN: {
    MAIN: "classes/assassin.webp",
  },
  BARD: {
    MAIN: "classes/bard.webp",
  },
  DEFENDER: {
    MAIN: "classes/defender.webp",
  },
  MAG: {
    MAIN: "classes/mag.webp",
  },
  WARRIOR: {
    MAIN: "classes/warrior.webp",
  },
};

export const AttributeImages = {
  AGILITY: {
    MAIN: "attributes/agility.webp",
  },
  CHARISMA: {
    MAIN: "attributes/charisma.webp",
  },
  ENDURANCE: {
    MAIN: "attributes/endurance.webp",
  },
  INT: {
    MAIN: "attributes/int.webp",
  },
  LUCK: {
    MAIN: "attributes/luck.webp",
  },
  STRENGTH: {
    MAIN: "attributes/strength.webp",
  },
};

export const SilhouetteImages = {
  SILHOUETTE_1: {
    main: "silhouette/1.png",
    preview: "silhouette/1_preview.webp",
  },
  SILHOUETTE_2: {
    main: "silhouette/2.png",
    preview: "silhouette/2_preview.webp",
  },
  SILHOUETTE_3: {
    main: "silhouette/3.png",
    preview: "silhouette/3_preview.webp",
  },
  SILHOUETTE_4: {
    main: "silhouette/4.png",
    preview: "silhouette/4_preview.webp",
  },
  SILHOUETTE_5: {
    main: "silhouette/5.png",
    preview: "silhouette/5_preview.webp",
  },
};

export const mobileBackground =
  "https://s3.pathgame.app/public/layout/background_mobile.webp";
export const desktopBackground =
  "https://s3.pathgame.app/public/layout/background_mobile.webp";

const prefixUrl = "https://s3.pathgame.app/public/";

export const attributesData = [
  {
    name: "Strength",
    description: `Great for Warrior
      <img src="${prefixUrl}${ClassImages.WARRIOR.MAIN}" alt="warrior icon" width="20px" height="20px"/> .`,
    icon: AttributeImages.STRENGTH.MAIN,
    classIcons: [ClassImages.WARRIOR.MAIN],
  },
  {
    name: "Agility",
    description: `Ideal for Assasins 
      <img src="${prefixUrl}${ClassImages.ASSASSIN.MAIN}" alt="assassin icon" width="20px" height="20px"/> .`,
    icon: AttributeImages.AGILITY.MAIN,
    classIcons: [ClassImages.ASSASSIN.MAIN],
  },
  {
    name: "Intelligence",
    description: `Crucial for 
      <img src="${prefixUrl}${ClassImages.MAG.MAIN}" alt="mage icon" width="20px" height="20px"/> .`,
    icon: AttributeImages.INT.MAIN,
    classIcons: [ClassImages.MAG.MAIN],
  },
  {
    name: "Endurance",
    description: `Beneficial for  
      <img src="${prefixUrl}${ClassImages.WARRIOR.MAIN}" alt="warrior icon" width="20px" height="20px"/>.`,
    icon: AttributeImages.ENDURANCE.MAIN,
    classIcons: [ClassImages.DEFENDER.MAIN],
  },
  {
    name: "Charisma",
    description: `Vital for 
      <img src="${prefixUrl}${ClassImages.BARD.MAIN}" alt="bard icon" width="20px" height="20px"/>.`,
    icon: AttributeImages.CHARISMA.MAIN,
    classIcons: [ClassImages.BARD.MAIN, ClassImages.ASSASSIN.MAIN],
  },
  {
    name: "Luck",
    description: `Increases chances of finding rare items and improves overall fortune in various tasks.`,
    icon: AttributeImages.LUCK.MAIN,
    classIcons: [],
  },
];

export const apiUrl = process.env.REACT_APP_API_URL;
