import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import { apiUrl } from "../../utils/enums";

const Wiki = () => {
  const [data, setData] = useState([]);

  const url = `${apiUrl}/market_items/receive_probability`;

  useEffect(() => {
    axios
      .get(url)
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  if (!data.length) {
    return <Loader />;
  }

  return (
    <div className={styles.container}>
      {data.map((item, index) => (
        <div key={item.title + index} className={styles.itemContainer}>
          <h2 className={styles.text}>{item.title}</h2>
          <p className={styles.text}>{item.description}</p>
          <img src={item.image_url} alt={item.title} className={styles.image} />
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Rarity</th>
                <th>Drop Chance</th>
                <th>Stat Range</th>
              </tr>
            </thead>
            <tbody>
              {item.receive_probability.map((probability, index) => (
                <tr key={index + probability.name}>
                  <td>{probability.name}</td>
                  <td>
                    {probability.receive_probability}%<br />
                  </td>
                  <td>
                    {probability.min} - {probability.max}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default Wiki;
